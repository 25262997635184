import React, { useContext } from "react"
import { Link, useStaticQuery } from "gatsby"
import { MenuContext } from "../../context/MenuContext"
import { motion, useCycle, AnimatePresence } from "framer-motion"
import { NavWrapper, StyledMainNav } from "./components.css"
import brownLogo from "../../images/BrownLaw_Logo_Dark.svg"

function LinkItem({ link, name, preview }) {
  const { toggleMenuOpen, isMenuOpen } = useContext(MenuContext)
  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
    out: { opacity: 0, x: -100 },
  }
  return (
    <Link to={link} onClick={toggleMenuOpen}>
      <motion.div style={{ margin: 20, width: 150 }} variants={item} exit="out">
        <span className="h4" style={{ color: `#ADADAD` }}>
          {name}
        </span>
        <hr
          style={{
            color: `var(--primary-blue)`,
            background: `var(--primary-blue)`,
            border: `none`,
            height: 2,
            opacity: 0.2,
            margin: `25px 0`,
          }}
        />
        <span
          style={{
            color: `#ADADAD`,
            fontSize: `var(--text-small)`,
            fontFamily: `Georgia, sans-serif`,
          }}
        >
          {preview}
        </span>
      </motion.div>
    </Link>
  )
}

function MainMenu() {
  const { toggleMenuOpen, isMenuOpen } = useContext(MenuContext)
  const [animate, cycleCard] = useCycle(
    // {
    //   menu:
    // },
    {
      card: { padding: "0rem" },
      image: {
        width: "125%",
        marginLeft: "-3rem",
        marginRight: "-3rem",
        marginTop: "-1rem",
      },
    }
  )
  const data = useStaticQuery(graphql`
    query MenuQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
            previewText
          }
        }
      }
    }
  `)

  const menuData = data.site.siteMetadata.menuLinks

  // menu animation
  const parent = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.8,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    out: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }
  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
    out: { opacity: 0, x: -100 },
  }
  return (
    <AnimatePresence>
      <StyledMainNav
        initial="hidden"
        animate={isMenuOpen ? "visible" : "hidden"}
        variants={parent}
        exit="hidden"
        key={`menu`}
      >
        {menuData.map(menuItem => (
          // <Link to={menuItem.link} onClick={handleChange(menuItem.link, !isNavOpen)}>{menuItem.name}</Link>
          <LinkItem
            link={menuItem.link}
            name={menuItem.name}
            preview={menuItem.previewText}
            key={menuItem.link}
            variants={item}
          />
          // <Link to={menuItem.link} onClick={toggleMenuOpen} >
          //   <motion.div style={{ margin: 20, width: 150 }} variants={item}>
          //     <span className="h4" style={{ color: `#ADADAD` }}>
          //       {menuItem.name}
          //     </span>
          //     <hr
          //       style={{
          //         color: `var(--primary-blue)`,
          //         background: `var(--primary-blue)`,
          //         border: `none`,
          //         height: 2,
          //         opacity: 0.2,
          //         margin: `25px 0`,
          //       }}
          //     />
          //     <span
          //       style={{
          //         color: `#ADADAD`,
          //         fontSize: `var(--text-small)`,
          //         fontFamily: `Georgia, sans-serif`,
          //       }}
          //     >
          //       {menuItem.previewText}
          //     </span>
          //   </motion.div>
          // </Link>
        ))}
      </StyledMainNav>
    </AnimatePresence>
  )
}

export default MainMenu
