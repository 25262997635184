import styled, { css } from 'styled-components';
import {motion} from 'framer-motion'

export const StyledLayout = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  background: var(--black);
  min-height: 100vh;
  width: 100vw;
  @media (min-width: 768px) {
    padding: 2rem 0;
  }
`

export const StyledHeader = styled.header`
    padding: 2rem;
`

export const StyledArticle = styled.article`
  padding: 2rem;

  .content-lead, .blog-content {
    font-size: calc(var(--heading-secondary) * .5);
    font-weight: 700;
    color: #fff;
    text-align: center;
    opacity: .5;
    @media (min-width: 768px) {
      font-size: calc(var(--heading-secondary) * .75);
    }
    @media (min-width: 1100px) {
      font-size: calc(var(--heading-secondary) * .9);
    }
  }
  .content-list {
    font-size: calc(var(--heading-tertiary) * .5);
    font-weight: 700;
    color: #fff;
    opacity: .5;
    
    @media (min-width: 768px) {
      font-size: calc(var(--heading-tertiary) * .75);
    }
    @media (min-width: 1100px) {
      font-size: var(--heading-tertiary);
    }
  }
`

export const HeadingOne = styled.h1`
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  background: linear-gradient(to right, #f33d32 0%, #f6cc00 75%, #f6cc00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-size: calc(var(--heading-main) * 0.6);
  line-height: calc(var(--heading-main) * 0.6 + 10px);
  @media (min-width: 768px) {
    font-size: calc(var(--heading-main) * 0.75);
    line-height: calc(var(--heading-main) * 0.75 + 10px);
  }
  @media (min-width: 1100px) {
    font-size: var(--heading-main);
    line-height: calc(var(--heading-main) + 10px);
  }
`
export const FancyLead = styled.p`
  margin: 0 auto; 
  width: 80vw;
  font-size: calc(var(--heading-secondary) * .6);
  font-weight: 700;
  text-align: center;
  background: linear-gradient(to right, #f33d32 0%, #f6cc00 75%, #f6cc00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (min-width: 768px) {
    font-size: calc(var(--heading-secondary) * 0.75);
    line-height: calc(var(--heading-secondary) * 0.75 + 10px);
  }
  @media (min-width: 1100px) {
    font-size: var(--heading-secondary);
    line-height: calc(var(--heading-secondary) + 10px);
  }
`

export const Nav = styled(motion.nav)`
  position: absolute;
  top: 4rem;
  display: flex;
  width: 100%;
  height: 75vh;
  padding: var(--padding-med);
  background: #fff;
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      a {
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        height: 50px;
        color: var(--black);
        font-size: var(--heading-four);
        padding: 0 1rem;
        cursor: pointer;
      }
    }
  }
  ${(props) => props.mobile && css`
    @media (min-width: 768px) {
      display: none;
    }
  `}
  ${(props) => props.mainNav && css`
    display: none;
    background: var(--black);
    @media (min-width: 768px) {
      display: flex;
      a {
        color: #fff;
        opacity: 0.5;
      }
    }
  `}
  @media (min-width: 768px) {
    position: relative;
    top: 0;
    justify-content: center;
    height: auto;
    padding: 0;
    ul {
      flex-direction: row;
      li {
        a{
          justify-content: center;
          align-content: center;
          color: #fff;
          opacity: .5;
        }
      }
    }
  }
`

export const NavButton = styled.div`
  position: relative;
  top: 1.5rem;
  left: 1.5rem;
  width: 25px;
  @media (min-width: 768px) {
    display: none;
  }
`