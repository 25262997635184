import styled from "styled-components"
import { motion } from "framer-motion"

export const NavWrapper = styled.div`
  .nav-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    padding: 100px;
    background: var(--nebula-blue);
    z-index: 10;
    width: 30%;
    height: 100%;
    img {
      margin-bottom: 5rem;
    }
    a {
      display: block;
      text-align: left;
      font-size: var(--heading-tertiary);
      color: var(--white);
      text-decoration: none;
      line-height: var(--heading-tertiary);
      transition: 0.3s ease border;
      border-bottom: solid 4px transparent;
      margin-bottom: 2rem;

      &:hover {
        border-bottom: solid 4px var(--white);
      }
    }
 }
`

export const MenuButton = styled.button`
  appearance: none;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  background: transparent;
  border: none;
  z-index: 15;
`
export const StyledMainNav = styled(motion.nav)`
  padding: var(--padding-small);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 700px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    max-width: 500px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    span:last-child {
      display: none;
    }
  }
`
