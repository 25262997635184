import React from 'react'
import { HeadingOne } from './components.css'

function FancyTitle({title, size}) {
    return (
        <HeadingOne>
            {title}
        </HeadingOne>
    )
}

export default FancyTitle
