import React from 'react'
import { StyledHeader } from './components.css'

function Header({children}) {
    return (
        <StyledHeader>
            {children}
        </StyledHeader>
    )
}

export default Header
