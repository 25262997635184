import React, {useState} from "react"
import { StyledLayout } from '../components.css'
import "./global.css"
import Nav from "../Nav"
import Navigation from '../Navigation'
import MenuButton from '../MenuButton'
import "typeface-noto-sans/index.css"

function Layout({ children }) {
  const [isNavOpen, setNavOpen] = useState(false);
  return (
    <StyledLayout>
      <Navigation isNavOpen={isNavOpen} />
      <MenuButton onClick={() => setNavOpen(!isNavOpen)} isOpen={isNavOpen}/>
      <main>
        {children}
      </main>
    </StyledLayout>
  )
}

export default Layout
