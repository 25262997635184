import React from 'react'
import { Link } from 'gatsby'
import { Nav } from './components.css'

const variants = {
    open: { opacity: 1, x: 0 },
    closed: {
        opacity: 0,
        x: '-100%',
        transition: {
            delay: 0.2,
        },
    },
};

// const linkVariants = {
//     open: {
//         y: 0,
//         opacity: 1,

//     },
//     closed: {
//         y: -20,
//         opacity: 0,
//     },

// };

function Navigation({isNavOpen}) {
    return (
        <>
        <Nav
            variants={variants}
            initial="closed"
            animate={isNavOpen ? 'open' : 'closed'}
            transition={{ damping: 300 }}
            mobile
        >
            <ul>
                <li><Link to='/about'>About</Link></li>
                <li><a href="#">Work</a></li>
                <li><a href="#">Cool Stuff</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Contact</a></li>
            </ul>
        </Nav>
        <Nav
            mainNav
        >
            <ul>
                <li><Link to='/about'>About</Link></li>
                <li><a href="#">Work</a></li>
                <li><a href="#">Cool Stuff</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Contact</a></li>
            </ul>
        </Nav>
        </>
    )
}

export default Navigation
