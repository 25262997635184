import React from 'react';
import { motion } from 'framer-motion';
import { NavButton } from './components.css'

const MenuButton = ({
    isOpen = false,
    width = 24,
    height = 24,
    strokeWidth = 1,
    color = '#fff',
    transition = null,
    ...props
}) => {
    const variant = isOpen ? 'opened' : 'closed';
    const top = {
        closed: {
            rotate: 0,
            translateY: 0,
        },
        opened: {
            rotate: 45,
            translateY: 2,
        },
    };
    const center = {
        closed: {
            opacity: 1,
        },
        opened: {
            opacity: 0,
        },
    };
    const bottom = {
        closed: {
            rotate: 0,
            translateY: 0,
        },
        opened: {
            rotate: -45,
            translateY: -2,
        },
    };
    const lineProps = {
        stroke: color,
        strokeWidth,
        vectorEffect: 'non-scaling-stroke',
        initial: 'closed',
        animate: variant,
        transition,
    };
    const unitHeight = 6;
    const unitWidth = (unitHeight * (width)) / (height);

    return (
        <NavButton role="button" className="nav-trigger">
            <motion.svg
                viewBox={`0 0 ${unitWidth} ${unitHeight}`}
                overflow="visible"
                preserveAspectRatio="none"
                width={width}
                height={height}
                {...props}
            >
                <motion.line
                    x1="0"
                    x2={unitWidth}
                    y1="0"
                    y2="0"
                    variants={top}
                    {...lineProps}
                />
                <motion.line
                    x1="0"
                    x2={unitWidth}
                    y1="2"
                    y2="2"
                    variants={center}
                    {...lineProps}
                />
                <motion.line
                    x1="0"
                    x2={unitWidth}
                    y1="4"
                    y2="4"
                    variants={bottom}
                    {...lineProps}
                />
            </motion.svg>
        </NavButton>
    );
};

export default MenuButton;
