import React, {useState} from 'react'
import {AnimatePresence,motion} from 'framer-motion'
import styled from 'styled-components'


const variants = {
    open: {opacity:1, x: 0},
    closed: {
        opacity: 0,
        x: '-100%',
        transition: {
            delay: 0.2
        }
    }
}
const linkVariants = {
    open: {
        y:0,
        opacity:1,
       
    },
    closed: {
        y:-20,
        opacity: 0,
    },
    
}
const ulVariants = {
    open: {
       scale: 1.05 , 
        transition: {
           staggerChildren: 0.3,
           delayChildren:0.2,
           staggerChildren: -1, // 1 forwards, -1 backwards
           when: "afterChildren" // after children or beforeChildren = before children
        }
    },
    closed: {
        scale:1,
    },

}
const links = ['Home', 'About', 'Blog', 'Contact']

function Nav({isNavOpen, setNavOpen}) {


    return (
        <MenuNav 
        variants={variants} 
        initial='closed' 
        animate={isNavOpen ? 'open' : 'closed'}
         transition={{ damping: 300 }}
        >
            <button onClick={() => setNavOpen(false)}>Close </button>
            <motion.ul variants={ulVariants }>
                {links.map(link => (
                  <motion.li variants={linkVariants } key={link}>
                    <a href='#'>{link}</a>
                </motion.li>  
                ))}
                
                
            </motion.ul>
        </MenuNav>
    )
}

const MenuNav = styled(motion.nav)`
position: fixed;
top:0;
left:0;
width: 100vw;
height: 100vh;
background: var(--black);
padding: 40px;
a {
    color:white;
    text-decoration:none;
    border-bottom: 2px solid transparent;
    transition: 0.3s ease border;
    &:hover {
        border-bottom-color: var(--blue);
    }
}
 ul {
     list-style:none;
     padding: 0;
     margin:0;
     li {
         list-style:none;
     padding: 0;
     margin:0 0 1rem;
     font-size: 2rem;
     }
 }
`

export default Nav
